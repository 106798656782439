import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { Title, Section, Box, LinkContact, Button } from '../../components/Core'
import { projects } from '../../data/projects'
// import bgFooter from '../../assets/webp-assets/subscribe-pattern.webp'
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';


const SectionStyled = styled(Section)`
  &.pattern::before {
    position: absolute;
    bottom: -150px;
    content: '';
    width: 120%;
    height: 150%;
    ${'' /* background: url(${bgFooter}) bottom center no-repeat; */}
    background-size: cover;
    z-index: -1;
  }
`


const Contact = ({ pattern = true }) => {
  const location = useLocation();


  const handleNextProjectClicked = () => {
    const currentPath = location.pathname;
    const currentProjectIndex = projects.findIndex((currProject, currIndex) => currProject.link === currentPath);
    const nextProjectIndex = currentProjectIndex === projects.length - 1 ? 0 : currentProjectIndex + 1;
    navigate(projects[nextProjectIndex].link)

  }
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled
        className={`position-relative ${pattern ? 'pattern' : ''}`}
      >
        <Container>
          <Row className="justify-content-center align-items-center">
            <Button
              arrowRight
              variant="secondary"
              onClick={handleNextProjectClicked}
              color="#7A60ED"
              className="border-0 bg-transparent shadow-none text-capitalize py-3 mb-5"
              css={`
                    font-weight: 700;
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;;
                `}
            >
              Next project
            </Button>
            <Col lg="11" xl="10" className="text-center">
              <Box>
                <Title variant="secSm">
                  I am taking new projects, so if you'd like to chat let's get
                  in touch.
                </Title>



                <Box
                  className="text-center d-flex justify-content-center"
                  mt={['3rem', null, '4rem']}
                >
                  <div className="d-flex flex-column flex-lg-row justify-content-center">
                    <LinkContact
                      href="mailto:beta.nycz@icloud.com"
                      target="_blank"
                      className="mb-2 mb-lg-0"
                    >
                      beta.nycz@icloud.com
                    </LinkContact>
                    <span className="divider mx-2 mx-lg-4 d-none d-lg-block">
                      |
                    </span>
                    <LinkContact
                      href="https://www.linkedin.com/in/beta-nycz/"
                      target="_blank"
                    >
                      linkedin.com/in/beta-nycz
                    </LinkContact>
                  </div>
                </Box>
              </Box>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  )
}

export default Contact
