import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, Button, Title, Text, Box } from '../components/Core'
import PageWrapper from '../components/PageWrapper'
import Contact from '../sections/common/Contact'
import imgWorkCover from '../assets/image/cover/wayw-cover.jpg'
import vid from '../assets/videos/bvideomock.mp4'
import imgS3 from '../assets/image/details/wave/trippy.jpg'
import imgS4 from '../assets/image/details/wave/smartwatch.jpg'
import imgS5 from '../assets/image/details/wave/Moodboard.jpg'

// import synth from '../assets/videos/synth.mp4';
// const handleLiveWorkClicked = event => {
//   event.stopPropagation()
//   window.open('https://www.berlinomania.pl/', '_blank')
// }

const wave = {}

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className="px-sm-5"
          css={`
            margin-top: 92px;
          `}
        >
          <img src={imgWorkCover} alt="" className="img-fluid w-100" />
        </Container>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">UI/UX</Text>
                <Title variant="secSm" className="my-4">
                  Project Overview{' '}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  {' '}
                  As a modular synths enthusiast, I identified a market gap- the lack of classified apps, akin to eBay, exclusively for synths. Despite the existence of various channels, forums, and generic music platforms for gear purchases, the absence of a unified app was evident. My objective was to design a platform that not only unifies community aspects but also establishes connections between buyers and sellers, fostering sustainability within the community.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Personal Project</Text>
                <Title variant="cardBig" className="mt-3">
                  Wave
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Time Scope</Text>
                <Title variant="cardBig" className="mt-3">
                  1 Month
                </Title>
              </Col>
              <Col lg="4">
                {/* <Button onClick={handleLiveWorkClicked} arrowRight>
                  Live work
                </Button> */}
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              {
                <Col xs="12" className="mb-5">
                  {/* <video width="400" controls autoPlay>
                    <source src={synth} type="video/mp4"></source>
                  </video> */}
                </Col>
              }
              <Col xs="12" className="mb-5">
                <img src={imgS3} alt="" className="img-fluid w-100" />
              </Col>

              <Col xs="12" className="mb-5">
                <img src={imgS4} alt="" className="img-fluid w-100" />
              </Col>
              <Col xs="12" className="mb-5">
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>
              {/* <Col xs="12" className="mb-5 mt-5">
                <video
                  className="video-size"
                  style={{ maxWidth: '100%' }}
                  controls
                >
                  <source
                    src={vid}
                    type="video/mp4"
                    className="img-fluid w-100"
                  />
                </video>
              </Col> */}
            </Row>
          </Container>
        </Section>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4">
              Process
            </Title>
            <Row>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  1. Strategy
                </Title>
                <Text variant="p">
                  Conceiving ideas, conducting in-depth research with the community, designing iteratively, and reviewing to ensure alignment with user needs and preferences.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  2. Deliverables
                </Title>
                <Text variant="p">
                  - Concept <br />
                  - Market Research<br />
                  - Copy<br />
                  - UI/UX Web App Design <br />
                </Text>
              </Col>
              {/* <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  3. Strategy
                </Title>
                <Text variant="p">
                  Conceiving ideas, conducting in-depth research with the community, designing iteratively, and reviewing to ensure alignment with user needs and preferences.
                </Text>
              </Col> */}
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  3. Key Learnings
                </Title>
                <Text variant="p">
                  Navigating solo development on complex products from end to end is both challenging and presents valuable opportunities for growth.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        {/* <Section mt="22%" bg="dark">
          <Container>
            {/* <Row

              css={`
                margin-top: -40%;
              `}
            >
              { <Col xs="12" className="mb-5">
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>}
            </Row> }

            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="portfolio-details">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Replace
                </Button>
              </Link>
            </div>
          </Container>
        </Section> */}
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  )
}
export default WorkSingle
